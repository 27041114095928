import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export const inputStyles = (theme) => css`
  width: 28.625rem;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: 18rem;
  }

  @media screen and (max-width: ${theme.breakpoints.xs}) {
    width: 15rem;
  }
`;

const filledStyles = (theme) => css`
  input,
  select,
  textarea {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.text};
    color: ${theme.colors.text};
  }

  label {
    color: ${theme.colors.text};
    top: -0.75rem;
  }
`;

const errorStyles = (theme) => css`
  input,
  select,
  textarea {
    border-color: ${theme.colors.error};
  }

  label {
    color: ${theme.colors.error};
  }
`;

const getInputStyles = (theme, value) => value && filledStyles(theme);
const getInputErrorStyles = (error, theme) => error && errorStyles(theme);

export const IconRight = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.25rem;
  right: 1rem;
  top: 0.625rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const ButtonText = styled.span`
  flex: 1;
  justify-content: center;
  width: auto;
`;

export const Button = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 3.125rem;
  box-shadow: ${({ theme }) => theme.boxShadow.base};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  font-weight: 500;
  height: 3.438rem;
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  margin: 3.5rem auto 1.5rem auto;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 50%;

  &:disabled {
    background-color: ${({ theme }) => theme.color.primary};
    box-shadow: ${({ theme }) => theme.boxShadow.none};
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 640px) {
    margin: 1.5rem auto 0 auto;
  }

  ${({ styles, theme }) => styles(theme)};
`;

export const StyledSecondaryButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 3.125rem;
  box-shadow: ${({ theme }) => theme.boxShadow.base};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  font-weight: 500;
  height: 2.385rem;
  justify-content: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  margin: 0 1.125rem 0.5rem 1.125rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 7.813rem;

  &:disabled {
    background-color: ${({ theme }) => theme.color.secondary};
    box-shadow: ${({ theme }) => theme.boxShadow.none};
    opacity: 0.5;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 0 0.75rem 0.5rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 0 0 0.75rem 0;
  }

  ${({ styles, theme }) => styles(theme)};
`;

export const Error = styled.span`
  background-color: #d95d6a1a;
  border-radius: 0.625rem;
  color: ${({ theme }) => theme.colors.error};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: 0.3rem 0.8rem;
  width: 100%;
`;

export const FormContent = styled.form`
  ${({ styles, theme }) => styles(theme)};
`;

export const HelpLink = styled(Link)`
  color: ${({ theme }) => theme.color.blue700};
  margin-bottom: 0.25rem;
  text-decoration: underline;
  margin-left: auto;

  &:hover {
    color: ${({ theme }) => theme.color.blue800};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 auto;
  }
`;

export const InputAlt = styled.input`
  font-size: 1rem;
  height: 2.18rem;
  background: ${({ theme }) => theme.colors.white};
  width: 12.5rem;
`;

export const InputCheckbox = styled.input`
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray100};
  border-radius: 2.5rem;
  border-width: 0.063rem;
  color: ${({ theme }) => theme.color.gray700};
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 1.25;
  margin: 0.25rem 1.188rem 1.313rem 0.25rem;
  padding: 1rem;

  &:focus {
    border-color: ${({ theme }) => theme.color.gray300};
    outline: 0;
  }

  ${({ styles }) => styles};
`;

export const InputContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: ${({ error }) => error && '0.25rem'};
  position: relative;
  width: 100%;

  &:focus-within {
    label {
      color: ${({ theme }) => theme.colors.secondary};
      top: -0.75rem;
    }
  }

  ${({ theme, value }) => getInputStyles(theme, value)}
  ${({ error, theme }) => getInputErrorStyles(error, theme)}
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.color.gray700};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 400;
`;

export const LabelContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.25rem;
`;

export const loadingStyles = () => css`
  margin: 0 auto;
`;

export const Span = styled.span`
  display: block;
  margin-bottom: 0.5rem;
`;
