import styled from '@emotion/styled';

export const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-bottom: 0.813rem;
  position: relative;
  z-index: 1;
`;

export const Option = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 3.125rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  padding: 0.313rem 0.75rem 0.313rem 2rem;
  position: relative;
  z-index: 1;
`;

export const OptionText = styled.span`
  margin-left: ${({ icon }) => (icon ? 0 : '-1rem')};
`;

export const DeleteIcon = styled.span`
  background-color: ${({ theme }) => `${theme.colors.white}4D`};
  border-radius: 50%;
  min-height: 0.938rem;
  margin-left: 0.625rem;
  padding: 0.25rem;
  min-width: 0.938rem;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  filter: ${({ theme }) => theme.filters.white};
  height: 100%;
  width: 100%;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 1.2rem;
  justify-content: center;
  position: absolute;
  left: 0.3rem;
  width: 1.2rem;
`;
