import { useRef, useState } from 'react';

import { useClickAway } from '../../hooks/events';
import Body from './Body';
import Header from './Header';

import { Container } from './Sidebar.styles';

const Sidebar = () => {
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef();

  const showSidebar = () => setIsOpened((prevState) => !prevState);

  useClickAway(ref, () => {
    setIsOpened(false);
  });

  return (
    <>
      <Header openMenu={showSidebar} isOpened={isOpened} />
      <Container aria-label="nav-bar" isOpened={isOpened}>
        <Body isOpened={isOpened} ref={ref} />
      </Container>
    </>
  );
};

export default Sidebar;
