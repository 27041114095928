import styled from '@emotion/styled';

export const Label = styled.label`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.disabledText};
  font-size: 1rem;
  left: 1.25rem;
  pointer-events: none;
  position: absolute;
  top: 0.438rem;
  transition: 0.2s ease all;
`;

export const TextArea = styled.textarea`
  color: ${({ theme }) => theme.colors.text};
  border: 0.063rem solid ${({ theme }) => theme.colors.borderGray};
  border-color: ${({ theme, value }) =>
    value ? theme.colors.text : theme.colors.disabledText};
  border-radius: ${({ theme }) => theme.borderRadius.mg};
  font-size: ${({ theme }) => theme.fontSize.sm};
  height: 4.375rem;
  padding: 0.625rem 0.75rem 0rem 0.75rem;
  resize: none;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ styles, theme }) => styles(theme)};
`;
