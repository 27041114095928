import { useIntl } from 'react-intl';

import AuthWrapper from '../AuthWrapper';
import MainForm from './MainForm';

const SignIn = () => {
  const intl = useIntl();

  return (
    <AuthWrapper title={intl.messages['common.signIn']}>
      <MainForm />
    </AuthWrapper>
  );
};

export default SignIn;
