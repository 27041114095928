import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';

import FormAssignationInput from './AssignationInput';
import FormButton from './Button';
import FormCheckbox from './Checkbox';
import FormInput from './Input';
import PasswordInput from './Password';
import FormResultsInput from './ResultsInput';
import FormSecondaryButton from './SecondaryButton';
import FormSecondarySelect from './SecondarySelect';
import FormSelect from './Select';
import FormTextArea from './TextArea';

import { Error, FormContent } from './Form.styles';

const Form = ({ children, formMethods, onSubmit, styles, ...props }) => {
  const { errors, handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <FormContent {...props} onSubmit={handleSubmit(onSubmit)} styles={styles}>
        {children}
      </FormContent>
      {errors?.general && <Error>{errors.general.message}</Error>}
    </FormProvider>
  );
};

Form.displayName = 'CustomForm';
Form.AssignationInput = FormAssignationInput;
Form.Button = FormButton;
Form.Checkbox = FormCheckbox;
Form.Input = FormInput;
Form.Password = PasswordInput;
Form.ResultsInput = FormResultsInput;
Form.SecondaryButton = FormSecondaryButton;
Form.SecondarySelect = FormSecondarySelect;
Form.Select = FormSelect;
Form.TextArea = FormTextArea;

Form.defaultProps = {
  onSubmit: () => {},
  styles: () => {},
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  formMethods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  styles: PropTypes.func,
};

export default Form;
