import logo from 'assets/images/techunited-logo.png';
import logoMd from 'assets/images/techunited-logo--md.png';
import logoSm from 'assets/images/techunited-logo--sm.png';

import { Container, Img } from './Logo.styles';

const Logo = () => (
  <Container>
    <picture>
      <source srcSet={logoSm} media="(max-width: 640px)" />
      <source srcSet={logoMd} media="(max-width: 768px)" />
      <Img src={logo} alt="Logo" />
    </picture>
  </Container>
);

export default Logo;
