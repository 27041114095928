import { useReducer } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { checkPasswordStrength } from 'helpers/regex';
import { EMPTY, MEDIUM, STRONG, WEAK } from 'utils/constants';
import Input from './Input';

import { MessageContainer, Line } from './Password.styles';

const reducer = (state, action) => {
  switch (action.strength) {
    case STRONG:
      return STRONG;
    case MEDIUM:
      return MEDIUM;
    case WEAK:
      return WEAK;
    default:
      return EMPTY;
  }
};

const Password = ({ defaultValue, name, placeholder }) => {
  const [strength, dispatch] = useReducer(reducer, EMPTY);

  const handleOnChange = ({ target }) => {
    dispatch({ strength: checkPasswordStrength(target.value) });
  };

  const getId = () => {
    const defaultId = 'common.weak';

    const ids = {
      [STRONG]: 'common.strong',
      [MEDIUM]: 'common.medium',
    };

    return ids[strength] || defaultId;
  };

  return (
    <>
      <Input
        defaultValue={defaultValue}
        name={name}
        type="password"
        placeholder={placeholder}
        onChange={handleOnChange}
      />
      {strength !== EMPTY && (
        <MessageContainer>
          <Line strength={strength} />
          <FormattedMessage id={getId()} />
        </MessageContainer>
      )}
    </>
  );
};

Password.defaultProps = {
  defaultValue: '',
};

Password.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Password;
