import { useCurrentCompany } from 'features/app/contexts/company';
import { useAuth } from 'features/auth';

export const useIsOwner = () => {
  const data = useCurrentCompany();
  const { user } = useAuth();

  if (!data || !data.company) return false;

  const { company } = data;

  return company.ownerId === user.id;
};
