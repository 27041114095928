import palette from './palette';
import oldTheme from './oldTheme';

const newTheme = {
  border: {
    thin: '1px solid',
  },
  borderRadius: {
    base: '0.25rem',
    full: '9999px',
    lg: '0.5rem',
    md: '0.375rem',
    none: '0',
    sm: '0.125rem',
    mg: '1.25rem',
    xl: '1.875rem',
    x4l: '2rem',
    exl: '3.125rem',
  },
  boxShadow: {
    base: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    darkMd:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    darkLg:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    darkXl:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    lightGray: '1px 1px 20px 10px rgba(0, 0, 0, 0.05)',
    none: 'none',
    modalContainerShadow:
      '0.063rem 0.063rem 1.25rem 0.625rem rgba(0, 0, 0, 0.1)',
  },
  breakpoints: {
    lg: '1024px',
    md: '768px',
    sm: '640px',
    xs: '414px',
    xl: '1280px',
    xxl: '1400px',
    sxl: '1600px',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    xxl: '1.5rem',
    x3l: '1.875rem',
    x4l: '2rem',
    x5l: '2.25rem',
    x6l: '3rem',
    x7l: '4rem',
    x8l: '6rem',
  },
  height: {
    mobileSidebarHeader: '3.5rem',
    footer: '3.25rem',
    sidebarHeader: '8.2rem',
    sidebarUser: '5.2rem',
  },
  letterSpacing: {
    normal: '0',
    tight: '-0.025rem',
    tighter: '-0.05rem',
    wide: '0.025rem',
    wider: '0.05rem',
    widest: '0.1rem',
  },
  ...palette,
  width: {
    sidebar: 'clamp(12rem, 15%, 20rem)',
  },
};

const theme = { ...oldTheme, ...newTheme };

export default theme;
