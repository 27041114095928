import {
  faAngleRight,
  faArrowLeft,
  faBars,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

const icons = [faCheck, faBars, faAngleRight, faArrowLeft];

export default icons;
