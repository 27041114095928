import ForgotPassword from '../components/ForgotPassword';
import AuthLayout from '../layouts/AuthLayout';

const ForgotPasswordPage = () => (
  <AuthLayout noSidebar>
    <ForgotPassword />
  </AuthLayout>
);

export default ForgotPasswordPage;
