const messages = {
  auth: {
    account: 'Account',
    billingContact: 'Billing Contact',
    changePassword: 'Change Password',
    companyGoals: 'Company Goals',
    companyLogo: 'Company Logo',
    companyProfile: 'Company Profile',
    completeYourProfile: 'Complete your profile!',
    editProfile: 'Edit Profile',
    faq: 'FAQ',
    goalsDescription:
      'Which of the following apply to your organization? Click to enable/disable',
    keyword: 'Keyword',
    keywords: 'Keywords',
    logOut: 'Log Out',
    membership: 'Membership',
    passwordReset: 'Password Reset',
    personalProfile: 'Personal Profile',
    preview: 'Preview',
    primaryContact: 'Primary Contact',
    reEnterPassword: 'Re-enter Password',
    resetPassword: 'Reset Password',
    saveChanges: 'Save Changes',
    updatedPasswordSuccessfully: 'Your password was changed successfully.',
  },
  common: {
    noCompanyContactInfo: 'This company doesn’t have any contact information',
    accountSettings: 'Account settings',
    addTeammate: 'Add teammate',
    alreadyHaveAnAccount: 'Already have an account?',
    apply: 'Apply',
    arrow: 'Arrow',
    benefit: 'Benefit',
    billingAddress: 'Billing Address',
    birthDate: 'Date of Birth',
    cancel: 'Cancel',
    changePasswordSuccess: 'Your password has been changed successfully',
    city: 'City',
    code: 'Code',
    companyDescription: 'Company Description',
    companyGoals: 'Company Goals',
    companyIndustry: 'Company Industry',
    companyName: 'Company Name',
    companyRole: 'Company Role',
    companyType: 'Company Type',
    companyWebsite: 'Company Website',
    confirm: 'Submit',
    confirmPassword: 'Confirm password',
    connect: 'Connect',
    contact: 'Contact',
    contactUs: 'Contact Us',
    continue: 'Continue',
    copyright: 'Copyright © 2021 TechUnited:NJ. All rights reserved.',
    corpsite: 'Membership page form corp site',
    country: 'Country',
    couponCodeApplied: 'Discount code',
    createAccount: 'Create one here',
    createNewAccount: 'Create an account',
    currentPassword: 'Current Password',
    delete: 'Delete',
    department: 'Department',
    disclaimerDescription: 'Images with other ratios may be cropped.',
    disclaimerRatio: 'Please upload a 1:1 ratio image.',
    dontHaveAccountQuestion: 'Not a member?',
    edit: 'Edit',
    editBillingAddress: 'Edit Billing Address',
    editPaymentMethod: 'Edit Payment Method',
    email: 'Email',
    errorBoundaryMessage: 'An error occurred',
    errorMessage: 'There was an error',
    expired: 'Expired',
    expires: 'Will the offer expire?',
    facebook: 'Facebook',
    filterBy: 'Filter by',
    firstName: 'First Name',
    forgotPassword: 'Forgot password?',
    forgotPasswordChange: 'Enter your new password',
    forgotPasswordEmailResend: 'Didn’t get a code?',
    forgotPasswordEmailSent:
      'Please enter the verification code we just sent to your email to be able to reset your password.',
    forgotPasswordLegend:
      'Please enter your email address. You will receive a link to create a new password via email.',
    fullName: 'Full Name',
    gender: 'Gender',
    general: 'General',
    goals: 'Goals',
    goBack: 'Back',
    goBackHome: 'Go back home',
    greeting: 'Welcome',
    greetingTo: 'Welcome To ',
    howRedeemed: 'How is this offer redeemed?',
    icon: 'Icon',
    image: 'Image',
    industry: 'Industry',
    instagram: 'Instagram',
    instructions: 'Instructions',
    invalidEmail: 'Invalid email',
    invalidIndustry: 'Must be a valid industry',
    invitations: 'Invitations',
    inviteTeammateSuccess: 'Invitation sent successfully',
    keywords: 'Keywords',
    lastName: 'Last Name',
    lengthToken: 'Must have six digits',
    link: 'Link',
    linkedin: 'Linkedin URL',
    locale: 'Language',
    medium: 'Medium',
    member: 'Member',
    membershipPlan: 'Membership Plan',
    name: 'Name',
    newJerseyAbbreviated: 'NJ',
    newPassword: 'New Password',
    next: 'Next',
    no: 'No',
    nonMember: 'Non member',
    noResults: 'No results',
    offerDescription: 'Offer Description',
    offerTitle: 'Offer Title',
    other: 'Other:',
    pageNotFoundLegend: 'We can’t seem to find the page you’re looking for.',
    pageNotFoundTitle: '404',
    pageNotFoundYellLegend: 'Oops! ',
    password: 'Password',
    passwordsDontMatch: 'The passwords don’t match',
    paymentMethod: 'Payment Method',
    pending: 'Pending',
    pendingWarning:
      'Welcome to your membership dashboard! Your account is pending approval by the TechUnited team. Please allow one business day for this review.',
    percentageCompleted: '{percentage}% completed',
    phone: 'Phone',
    primaryOwner: 'Primary Owner',
    primaryOwnerEmail: 'Primary Owner Email',
    private: 'Private',
    profile: 'Profile',
    qty: 'Qty',
    race: 'Race',
    rememberMe: 'Remember me',
    reportError: 'Report error',
    required: 'Required',
    reset: 'Reset',
    resetPassword: 'Reset Password',
    resetPasswordSuccess: 'Your password has been updated successfully',
    returnToHome: 'Return to Home',
    save: 'Save',
    saveChanges: 'Save Changes',
    seeMore: 'See more',
    selectFile: 'Select File',
    selectImage: 'Select Image',
    selectLanguage: 'Select language',
    selectOption: 'Select an option',
    shortPassword: 'Password too short, minimum length is 8 characters',
    signIn: 'Log in',
    signOut: 'Sign out',
    signUp: 'Sign up',
    skip: 'Skip',
    state: 'State',
    strong: 'Strong',
    submit: 'Submit',
    team: 'Team',
    teammates: 'Teammates',
    techUnited: 'TechUnited',
    terms: 'Terms',
    title: 'Title',
    token: 'Code',
    twitter: 'Twitter',
    type: 'Type',
    unverified: 'Unverified',
    updatePassword: 'Update Password',
    updateSettings: 'Update Settings',
    updateSettingsSuccess: 'Your settings have been updated successfully',
    usernameOrEmail: 'Username or email',
    verificationPassword: 'Verification Password',
    weak: 'Weak',
    website: 'Website',
    weFundraising: 'We are fundraising',
    weHire: 'We are hiring',
    weLookingCustomers: 'We are looking for new customers',
    weLookingPartnersOrProfServices:
      'We are looking for partners or professional services',
    weSpeaking: 'We´re interested in speaking opportunities in the community',
    yes: 'Yes',
  },
  company: {
    goals: 'Company goals',
    keywords: 'Keywords',
    logo: 'Logo',
    resultsNotFound: 'No results found',
    social: 'Social',
  },
  content: {
    allContent: 'All Content',
    download: 'Download File',
    membersOnly: 'Members Only',
    membersOnlyContent: 'Members Only Content',
    title: 'Content',
  },
  experiences: {
    helpMessage:
      'Ticket(s) will be assigned to the buyer. Registering someone else? Forward the ticket confirmation email or assign them',
    here: 'here',
    add: 'Add',
    apply: 'Apply',
    assignTicketsToBuyer: 'Assign all tickets to buyer',
    assignTitle: 'Assign your tickets',
    billingContact: 'Billing Contact',
    cart: 'Cart',
    category: 'Category',
    checkout: 'Checkout',
    checkoutDescription: 'You are proceeding with the purchase of {eventName}.',
    chooseTicket: 'Step 1: Choose your Ticket',
    community: 'Community',
    couponApplied: 'Coupon Applied',
    couponCode: 'Coupon Code',
    discountApplied: 'Discount Applied',
    done: 'Done',
    emailForTicket: 'Email',
    emailSent: 'An email confirmation was sent.',
    event: 'Event',
    experienceImage: 'Experience Image',
    free: 'Free',
    forRegistering: 'for registering!',
    nonMember: 'TechUnited:NJ Non-Member',
    noTicketsSelected: 'No tickets selected',
    paymentInfo: 'Please complete your payment information.',
    proceedCheckout: 'Proceed to Checkout',
    register: 'Register',
    registerEvent: 'Step 2: Register to this event',
    rsvp: 'Rsvp',
    soldOut: 'Sold out',
    successfully: 'Successfully',
    tag: 'Tag',
    thankYou: 'Thank you',
    ticketsAssigned: 'Assigned',
    title: 'Experiences',
    total: 'Total',
    yourMemberPrice: 'TechUnited:NJ Member',
    assignmentHelp:
      'By default, all ticket QR codes will be assigned and sent to the buyer’s email. It will be up to the buyer to later forward the tickets. You can assign tickets to other people here at checkout, by clicking assign tickets instead of later forwarding emails. This way the tickets will be sent directly to their email inbox.',
  },
  highlights: {
    invite: 'Invite Team',
    title: 'Highlights',
  },
  memberDirectory: {
    title: 'Member Directory',
  },
  networking: {
    brellaButtonText: 'Network & Schedule Your Meeting Today',
    brellaDescription:
      'Join the TechUnited:NJ workspace today to introduce yourself, share company news, seek advice and share expertise and stay updated about all things TechUnited.',
    brellaSubtitle:
      'TechUnited is AI-Powered Matchmaking & Networking Platform',
    slackButtonText: 'Say Hello in Slack!',
    slackDescription:
      'Set up a profile ASAP to schedule your meetings for our monthly live networking events. The entire day is dedicated to matching you with members of the TechUnited community based on your selected interests.',
    slackSubtitle: 'Connect & Network with TechUnited Members',
    title: 'Networking',
  },
  offers: {
    expires: 'Expires',
    redeem: 'Redeem this offer',
  },
  onboarding: {
    accessDashboard: 'Access Dashboard',
    activateMembership: 'Activate Membership',
    additionalBenefits: 'Additional Benefits',
    address: 'Address',
    billingContact: 'Billing Contact',
    cardNumber: 'Card Number',
    check: 'Check',
    city: 'City',
    companyDescription: 'Which of the following apply to your organization?',
    continueToBuildProfile: 'Please continue so we can build your profile',
    continueToCheckout: 'Continue to Checkout',
    country: 'Country',
    createAccount: 'Create one here',
    credentials: 'Credentials',
    creditCardType: 'Credit Card Type',
    cvv: 'CVV',
    duplicatedEmailError:
      'Invite not added. This domain is already in the invite list.',
    emailSent: 'An email confirmation was sent.',
    expandNetworkingDescription:
      'Invite your teammates to join your membership.',
    expirationDate: 'Expiration Date',
    featuredBenefits: 'Featured Benefits',
    fundraising: 'We are fundraising',
    goToDashboard: 'Go to Dashboard',
    hire: 'We are hiring',
    invitationInfo:
      '{owner} invited you to join as a team member of {company}. Please enter the following information to register at TechUnited.',
    invite: 'Invite',
    inviteLater: 'Invite later',
    inviteTeam: 'Invite Your Team',
    lookingCustomers: 'We are looking for new customers',
    lookingPartnersOrProfServices:
      'We are looking for partners or professional services',
    membership: 'Membership',
    membershipDescription: 'Membership built to propel your organization',
    money: '$',
    other: 'Other:',
    out: 'Out',
    paymentInformation: 'Payment Information',
    paymentSuccessful:
      'Payment information was sent to process and can´t be modified at this time. If you wish to change them contact',
    personal: 'Personal',
    pleaseDescribe: 'Please enter a description',
    pleaseExplain: 'Please Explain',
    pleaseSelect: 'Please select at least one option',
    profileDescription:
      'Complete your profile to add your listing to the Member Directory and unlock full access to the Member Dashboard',
    renewText: 'Renewed automatically on',
    seeDashboard: 'See My New Dashboard!',
    speaking: 'We´re interested in speaking opportunities in the community',
    state: 'State',
    stateOrProvince: 'State or Province',
    stepOne: 'Your Profile',
    stepThree: 'Expand your Networking',
    stepTwo: 'Company Profile',
    teammateGreeting: 'Hi, {email}',
    teammates: 'Teammates',
    techUnitedSupport: 'TechUnited Support.',
    verifiedMembership: 'was verified successfully!',
    yearUnitTime: 'Per Year',
    yourMembership: 'Your Membership',
  },
  promote: {
    body: 'Body',
    by: 'By',
    category: 'Category',
    company: 'Company',
    delete: 'Delete',
    deleteConfirmation: 'Are you sure you what to delete this {itemType}?',
    eventDate: 'Event Date and Start Time',
    eventDescription: 'Event Description',
    eventFormDescription:
      'Submit an upcoming event to be featured in our upcoming newsletter and social media.',
    eventName: 'Event Name',
    events: 'Events',
    eventSubtitle: 'My Events',
    eventTitle: 'Submit an Event',
    expirationDate: 'Date of Expiration',
    featuredImage: 'Featured image',
    news: 'News',
    newsFormDescription:
      'Submit recent news, initiatives and funding news! The TechUnited team will share these on our site and newsletters to the TechUnited community',
    newsSubtitle: 'My News',
    offerDescription:
      'We are creating one online listing for all of the great offers available exclusively to TechUnited:NJ members.\r\nThis listing will be a great way to discover new platforms, tools, educational resources, and more. We are actively working to provide you with offers from some of the best organizations supporting businesses, so if your organization would like to list an offer in this directory, please fill out the form below to submit your offer.',
    offers: 'Offers',
    offerSubtitle: 'My Offers',
    offerTerms: 'Terms to redeem the offer',
    offerTitle: 'Submit an Offer',
    pressLink: 'Press Link',
    registrationLink: 'Registration Link',
    techUnitedTeamResponse: 'TechUnited Team Response:',
    timeZone: 'Time Zone',
    title: 'Promote',
    titleEvent: 'Event Title',
    titleNews: 'News Title',
    titleOffer: 'Offer Title',
  },
  sidebar: {
    memberDashboard: 'Member Dashboard',
  },
  tour: {
    highlightDescription: `We group the most outstanding content, offers, and news within the
    Home so that you can easily access them`,
    highlightTitle: 'TechUnited Highlights',
    inviteTeamDescription: `Enjoy our content and take the opportunity to invite teammates to
    expand your networking`,
    inviteTeamTitle: 'In the meantime',
    memberDirectoryDescription: `Here you can find all the companies that are in the TechUnited
     community. Easily accessible with a filter system that helps you find
     their data more easily`,
    memberDirectoryTitle: 'Company data',
    networkingDescription: `Keep communication fluent with your teammates across the company by
    setting up the Slack channel.`,
    networkingTitle: 'Stay tune',
    profileStatusDescription: `Remember to complete your profile as soon as possible to enjoy the
    benefits and improve your visibility`,
    profileStatusTitle: 'Profile Status',
    promoteDescription: `Post your own content and promote yourself the way you always wanted`,
    promoteTitle: 'Promote yourself!',
    verificationPendingDescription: `To improve security and authenticity, all new members must be
    verified. For now, you may enjoy limited access to the Member
    Dashboard. You will receive an email as soon as your account has been
    verified. Most accounts are verified within 1 business day`,
    verificationPendingTitle: 'Verification Pending',
  },
};

export default messages;
