import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import PropTypes from 'prop-types';

import Form from 'features/app/components/Form';
import { handleErrors } from 'helpers/errors';
import { RESET_PASSWORD_STEPS } from './ForgotPassword';
import { useAuth } from '../../hooks/auth';

import { inputStyles, Legend, Title } from './ForgotPassword.styles';

const EmailForm = ({ onStepChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const { requestPasswordReset } = useAuth();

  const validationSchema = object().shape({
    email: string()
      .required(intl.messages['common.required'])
      .email(intl.messages['common.invalidEmail']),
  });

  const formMethods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async ({ email }) => {
    setIsLoading(true);

    try {
      await requestPasswordReset(email);
      onStepChange(RESET_PASSWORD_STEPS.emailSent);
    } catch (errors) {
      handleErrors(errors, formMethods.setError);
      setIsLoading(false);
    }
  };

  return (
    <Form
      data-testid="forgot-password-email-form"
      onSubmit={onSubmit}
      formMethods={formMethods}
    >
      <Title>
        <FormattedMessage id="common.resetPassword" />
      </Title>
      <Legend>
        <FormattedMessage id="common.forgotPasswordLegend" />
      </Legend>
      <Form.Input
        styles={inputStyles}
        name="email"
        type="email"
        placeholder={intl.messages['common.email']}
      />
      <Form.Button
        text={intl.messages['common.resetPassword']}
        isLoading={isLoading}
      />
    </Form>
  );
};

EmailForm.propTypes = {
  onStepChange: PropTypes.func.isRequired,
};

export default EmailForm;
