import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { object, ref, string } from 'yup';
import PropTypes from 'prop-types';

import Form from 'features/app/components/Form';
import { handleErrors } from 'helpers/errors';
import { useAuth } from '../../hooks/auth';

import { Title, passwordStyles } from './ForgotPassword.styles';

const PasswordForm = ({ token }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const { resetPassword } = useAuth();

  const validationSchema = object().shape({
    password: string().required(intl.messages['common.required']),
    confirmPassword: string().oneOf(
      [ref('password'), null],
      intl.messages['common.passwordsDontMatch']
    ),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ password }) => {
    setIsLoading(true);

    try {
      await resetPassword(password, token);
      history.push({
        pathname: '/',
        search: '?resetPassword=success',
      });
    } catch (errors) {
      handleErrors(errors, formMethods.setError);
      setIsLoading(false);
    }
  };

  return (
    <Form
      data-testid="forgot-password-password-form"
      onSubmit={onSubmit}
      formMethods={formMethods}
    >
      <Title>
        <FormattedMessage id="common.forgotPasswordChange" />
      </Title>
      <Form.Input
        data-testid="password"
        styles={passwordStyles}
        name="password"
        type="password"
        placeholder={intl.messages['common.password']}
      />
      <Form.Input
        data-testid="confirm-password"
        styles={passwordStyles}
        name="confirmPassword"
        type="password"
        placeholder={intl.messages['common.confirmPassword']}
      />
      <Form.Button
        text={intl.messages['common.confirmPassword']}
        isLoading={isLoading}
      />
    </Form>
  );
};

PasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordForm;
