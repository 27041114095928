import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { MEDIUM, STRONG } from 'utils/constants';

const weak = (theme) => css`
  background-color: ${theme.colors.error};
  width: 5rem;
`;

const medium = (theme) => css`
  background-color: ${theme.colors.pending};
  width: 11rem;
`;

const strong = (theme) => css`
  background-color: ${theme.colors.published};
  width: 22rem;
`;

const getStyles = (theme, strength) => {
  switch (strength) {
    case MEDIUM:
      return medium(theme);
    case STRONG:
      return strong(theme);
    default:
      return weak(theme);
  }
};

export const MessageContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.disabledText};
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 0 1rem;
  width: 100%;
`;

export const Line = styled.div`
  border-radius: 0.188rem;
  height: 0.125rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 50%;
  }

  ${({ theme, strength }) => getStyles(theme, strength)};
`;
