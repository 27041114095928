import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const activeStyles = (theme) => css`
  color: ${theme.color.darkGray};
  font-weight: 700;
  background: ${theme.color.gray};
  border-image: ${theme.gradients.primaryToBottom} 1;
  border-right: 0.25rem solid;
`;

const disabledStyles = (theme) => css`
  background-color: ${theme.colors.disabledText}4D;
  color: ${theme.colors.text};
  cursor: not-allowed;

  a,
  label {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const subNavActiveStyles = (theme) => css`
  background-color: #5267a9;
  color: ${theme.color.white};
  font-weight: 500;
`;

export const Container = styled.div`
  color: #5267a9;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 0rem;

  ${({ isActive, theme }) => isActive && activeStyles(theme)}
  ${({ isDisabled, theme }) => isDisabled && disabledStyles(theme)}
`;

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const Icon = styled.span`
  margin: 0rem 1rem;
`;

export const SubNavIcon = styled.span`
  align-items: center;
  display: flex;
`;

export const SubNavLink = styled(Link)`
  align-items: center;
  background-color: rgba(82, 103, 169, 0.1);
  color: #5267a9;
  display: flex;
  font-size: 0.9rem;
  padding: 1rem;
  justify-content: center;

  ${({ isActive, theme }) => isActive && subNavActiveStyles(theme)}
  ${({ isDisabled, theme }) => isDisabled && disabledStyles(theme)}
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.text};
`;
