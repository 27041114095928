import styled from '@emotion/styled';

export const Container = styled.nav`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: ${({ theme }) => theme.height.sidebarHeader};
  width: ${({ theme }) => theme.width.sidebar};
  z-index: 5;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-color: rgba(89, 89, 89, 0.2);
    top: 0;
    transform: translateX(${({ isOpened }) => (isOpened ? '0vw' : '-100vw')});
    transition: all 0.2s ease;
    width: 100%;
  }
`;

export const Content = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: ${({ isOpened }) => (isOpened ? 'row-reverse' : 'row')};
  justify-content: center;
  width: 100%;

  a {
    padding: 0.3rem;
    height: 2.688rem;
    min-height: 2.688rem;
    min-width: 5.688rem;
    width: 5.688rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    transform: scale(1.3);
  }

  @media screen and (max-width: ${({ theme }) =>
      `calc(${theme.breakpoints.xl} - 1px)`}) {
    transform: scale(1);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    transform: scale(1);

    a {
      margin-bottom: 0.3rem;
    }
  }
`;

export const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 0.063rem solid ${({ theme }) => theme.colors.blueGray};
  display: flex;
  height: ${({ theme }) => theme.height.sidebarHeader};
  justify-content: center;
  padding: 2.25rem 0.8rem;
  position: fixed;
  top: 0;
  width: ${({ theme }) => theme.width.sidebar};
  z-index: 5;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    box-shadow: 0rem -0.3rem 0.6rem 0.06rem #000;
    height: ${({ theme }) => theme.height.sidebarHeader};
    padding: 0.25rem 0.4rem;
    height: fit-content;
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

export const HeaderText = styled.h3`
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.8rem;
  width: min-content;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const LinkMenuIcon = styled.img`
  max-width: unset;
`;

export const MenuIcon = styled.img`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
    margin: 0 0.35rem 0.75rem;
  }
`;

export const BodyContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: calc(
    100% - ${({ theme }) => theme.height.sidebarHeader} -
      ${({ theme }) => theme.height.sidebarUser}
  );
  overflow-y: auto;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100%;
    width: 80%;
  }
`;
