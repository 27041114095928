import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import BackButton from 'features/common/components/BackButton';
import AuthWrapper from '../AuthWrapper';
import { paths } from '../../paths';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import TokenForm from './TokenForm';

export const RESET_PASSWORD_STEPS = {
  initial: 0,
  emailSent: 1,
  updatePassword: 2,
};

const ForgotPassword = () => {
  const intl = useIntl();
  const history = useHistory();
  const [resetStep, setResetStep] = useState(RESET_PASSWORD_STEPS.initial);
  const [token, setToken] = useState();

  const handleStepChange = (step) => setResetStep(step);

  const handleSaveToken = (newToken) => setToken(newToken);

  const handleRedirectTo = (path) => () => history.push(path);

  return (
    <AuthWrapper title={intl.messages['common.resetPassword']}>
      <>
        {resetStep === RESET_PASSWORD_STEPS.initial && (
          <>
            <BackButton onClick={handleRedirectTo(paths.signIn)} />
            <EmailForm onStepChange={handleStepChange} />
          </>
        )}
        {resetStep === RESET_PASSWORD_STEPS.emailSent && (
          <>
            <BackButton
              onClick={() => handleStepChange(RESET_PASSWORD_STEPS.initial)}
            />
            <TokenForm
              onSaveToken={handleSaveToken}
              onStepChange={handleStepChange}
            />
          </>
        )}
        {resetStep === RESET_PASSWORD_STEPS.updatePassword && (
          <>
            <BackButton
              onClick={() => handleStepChange(RESET_PASSWORD_STEPS.emailSent)}
            />
            <PasswordForm token={token} />
          </>
        )}
      </>
    </AuthWrapper>
  );
};

export default ForgotPassword;
