const oldTheme = {
  borderRadius: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    full: '9999px',
  },
  color: {
    black: '#000000',
    blue700: '#2b6cb0',
    blue800: '#2c5282',
    gray100: '#F7FAFC',
    gray200: '#EDF2F7',
    gray300: '#E2E8F0',
    gray600: '#718096',
    gray700: '#4A5568',
    gray800: '#2d3748',
    green700: '#2f855a',
    green800: '#276749',
    indigo200: '#c3dafe',
    indigo400: '#7F9CF5',
    indigo500: '#667EEA',
    indigo600: '#5a67d8',
    indigo700: '#4c51bf',
    red600: '#E53E3E',
    white: '#FFFFFF',
  },
  letterSpacing: {
    tighter: '-0.05rem',
    tight: '-0.025rem',
    normal: '0',
    wide: '0.025rem',
    wider: '0.05rem',
    widest: '0.1rem',
  },
};

export default oldTheme;
