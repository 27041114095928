import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import arrowBack from 'assets/images/arrow-back.svg';

import {
  BackButtonContainer,
  BackButtonText,
  ArrowBack,
} from './BackButton.styles';

const BackButton = forwardRef(({ isDisabled, onClick, ...rest }, ref) => (
  <BackButtonContainer
    disabled={isDisabled}
    ref={ref}
    type="button"
    onClick={onClick}
    {...rest}
  >
    <ArrowBack alt="Back" src={arrowBack} />
    <BackButtonText>
      <FormattedMessage id="common.goBack" />
    </BackButtonText>
  </BackButtonContainer>
));

BackButton.defaultProps = {
  isDisabled: false,
};

BackButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
