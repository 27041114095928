export const elements = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap',
    },
  ],
};

export const card = {
  iconStyle: 'solid',
  hidePostalCode: false,
  style: {
    base: {
      color: '#595959',
      fontWeight: 500,
      fontFamily: 'Red Hat Display, sans-serif',
      fontSize: '16px',
      iconColor: '#595959',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#595959',
      },
    },
    invalid: {
      iconColor: '#EB4335',
      color: '#EB4335',
    },
  },
};
