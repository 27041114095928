import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Icon from 'assets/images/menu.png';
import Logo from 'features/common/components/Logo';
import { CORP_LINK } from '../../utils/constants';

import {
  Content,
  HeaderContainer,
  HeaderText,
  MenuIcon,
} from './Sidebar.styles';

const Header = ({ openMenu, isOpened }) => (
  <HeaderContainer>
    <Content isOpened={isOpened}>
      <MenuIcon src={Icon} alt="Menu" onClick={openMenu} />
      <a href={CORP_LINK} target="_blank" rel="noreferrer">
        <Logo />
      </a>
      <HeaderText>
        <FormattedMessage id="sidebar.memberDashboard" />
      </HeaderText>
    </Content>
  </HeaderContainer>
);

Header.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
};

export default Header;
