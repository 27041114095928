import React, { StrictMode } from 'react'; // eslint-disable-line no-restricted-imports
import ReactDOM, { render } from 'react-dom';
import { ThemeProvider } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';

import { AuthProvider } from 'features/auth';
import App from 'features/app/components/App';
import httpClient from 'features/app/services/httpClient';
import { elements } from 'features/onboarding/utils/stripeOptions';
import * as serviceWorker from 'serviceWorker';
import packageInfo from '../package.json';
import theme from './theme';

// TODO: load key from .env and change it for correct one

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV,
  release: `tech-united-react@${packageInfo.version}`,
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG,
};

TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV !== 'production') {
  import('react-axe').then((axe) => axe.default(React, ReactDOM, 1000));
}

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripePromise} options={elements}>
          <AuthProvider httpClient={httpClient}>
            <App />
          </AuthProvider>
        </Elements>
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
