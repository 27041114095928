import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loading from '../Loading';

import {
  ButtonText,
  IconRight,
  loadingStyles,
  StyledSecondaryButton,
} from './Form.styles';

const SecondaryButton = forwardRef(
  ({ isDisabled, isLoading, isSuccess, styles, text, ...rest }, ref) => {
    const [success, setSuccess] = useState(isSuccess);

    useEffect(() => {
      setSuccess(isSuccess);

      const timeout = setTimeout(() => {
        isSuccess && setSuccess(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }, [isSuccess]);

    return (
      <StyledSecondaryButton
        ref={ref}
        type="submit"
        disabled={isDisabled || isLoading}
        styles={styles}
        {...rest}
      >
        {!isLoading && success ? (
          <IconRight icon="check" size="lg" />
        ) : (
          !isLoading && <ButtonText>{text}</ButtonText>
        )}
        {isLoading && <Loading styles={loadingStyles} />}
      </StyledSecondaryButton>
    );
  }
);

SecondaryButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isSuccess: false,
  styles: () => {},
};

SecondaryButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  text: PropTypes.string.isRequired,
  styles: PropTypes.func,
};

export default SecondaryButton;
