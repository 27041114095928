import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const inputStyles = (theme) => css`
  width: 28.625rem;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const passwordStyles = () => css`
  width: 28.625rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.375rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Legend = styled.p`
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 2.5rem;
  text-align: left;
`;

export const ButtonText = styled.div`
  flex: 1;
  justify-content: center;
  padding-left: 0.5rem;
`;
