import { css } from '@emotion/react';
import styled from '@emotion/styled';

import arrowSelect from 'assets/images/arrow-select.svg';

const filledStyles = (theme) => css`
  input,
  select {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.text};
    color: ${theme.colors.text};
  }

  label {
    color: ${theme.colors.text};
    top: -0.75rem;
  }
`;

const errorStyles = (theme) => css`
  input,
  select {
    border-color: ${theme.colors.error};
  }

  label {
    color: ${theme.colors.error};
  }
`;

const getInputStyles = (theme, value) => value && filledStyles(theme);
const getInputErrorStyles = (error, theme) => error && errorStyles(theme);

export const InputContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: ${({ error }) => error && '0.25rem'};
  position: relative;
  width: 100%;

  &:focus-within {
    label {
      color: ${({ theme }) => theme.colors.secondary};
      top: -0.75rem;
    }
  }

  ${({ theme, value }) => getInputStyles(theme, value)}
  ${({ error, theme }) => getInputErrorStyles(error, theme)}
  ${({ containerStyles = () => {}, theme }) => containerStyles(theme)}
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.colors.disabledText};
  border-radius: 2.5rem;
  border-width: 0.063rem;
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.sm};
  height: 2.5rem;
  line-height: 1.25;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ styles, theme }) => styles(theme)};
`;

export const Label = styled.label`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.disabledText};
  font-size: 1rem;
  left: 1.25rem;
  pointer-events: none;
  position: absolute;
  top: 0.438rem;
  transition: 0.2s ease all;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.color.red600};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.sm};
  max-width: fit-content;
`;

export const Select = styled.select`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${arrowSelect}) !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  border-color: ${({ theme }) => theme.colors.disabledText};
  border-radius: 2.5rem;
  border-width: 0.063rem;
  color: ${({ theme }) => theme.colors.text};
  height: 2.5rem;
  line-height: 1.25;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    outline: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 100%;
  }

  ${({ styles, theme }) => styles(theme)};
`;
