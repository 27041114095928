import { useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import ArrowIcon from 'assets/images/arrow.svg';
import defaultAvatar from 'assets/images/avatar--default.png';
import { useAuth } from 'features/auth/hooks/auth';
import { useIsOwner } from 'features/common/hooks/isOwner';
import { paths } from 'features/settings/paths';
import { useClickAway } from '../../../hooks/events';
import { CORP_LINK } from '../../../utils/constants';

import {
  Avatar,
  CircleContainer,
  Container,
  Menu,
  MenuItem,
  OptionsIcon,
  Text,
  Wrapper,
} from './UserOptions.styles';

const UserOptions = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { isAuthenticated, user, signOut } = useAuth();
  const isOwner = useIsOwner();

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const ref = useRef();

  const isUserOptionsActive = pathname.includes('settings');

  const goUserAccount = () => {
    history.push(paths.account);
  };

  const showMenu = (event) => {
    event.stopPropagation();

    setIsMenuOpened((prevState) => !prevState);
  };

  useClickAway(ref, () => {
    setIsMenuOpened(false);
  });

  const handleLogOut = async () => {
    await signOut();
    window.open(CORP_LINK, '_blank');
  };

  return (
    isAuthenticated && (
      <Wrapper ref={ref}>
        {isMenuOpened && (
          <Menu>
            {isOwner && (
              <Link to={paths.editCompany}>
                <MenuItem>
                  <FormattedMessage id="auth.companyProfile" />
                </MenuItem>
              </Link>
            )}
            <Link to={paths.editUser}>
              <MenuItem>
                <FormattedMessage id="auth.personalProfile" />
              </MenuItem>
            </Link>
            {isOwner && (
              <Link to={paths.membership}>
                <MenuItem>
                  <FormattedMessage id="auth.membership" />
                </MenuItem>
              </Link>
            )}
            <Link to={paths.faq}>
              <MenuItem>
                <FormattedMessage id="auth.faq" />
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogOut}>
              <FormattedMessage id="auth.logOut" />
            </MenuItem>
          </Menu>
        )}
        <Container isActive={isUserOptionsActive} onClick={goUserAccount}>
          <Avatar>
            <img src={user?.avatar || defaultAvatar} alt="Avatar" />
          </Avatar>
          <Text>{user.firstName}</Text>
          <OptionsIcon isMenuOpened={isMenuOpened} onClick={showMenu}>
            <CircleContainer>
              <img src={ArrowIcon} alt="Arrow" />
            </CircleContainer>
          </OptionsIcon>
        </Container>
      </Wrapper>
    )
  );
};

export default UserOptions;
