import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import PropTypes from 'prop-types';

import Form from 'features/app/components/Form';
import { handleErrors } from 'helpers/errors';
import { useAuth } from '../../hooks/auth';
import { RESET_PASSWORD_STEPS } from './ForgotPassword';

import { Legend, inputStyles, Title } from './ForgotPassword.styles';

const TokenForm = ({ onStepChange, onSaveToken }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { verifyPasswordReset } = useAuth();

  const validationSchema = object().shape({
    token: string()
      .length(6, intl.messages['common.lengthToken'])
      .required(intl.messages['common.required']),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ token }) => {
    setLoading(true);

    try {
      await verifyPasswordReset(token);
      onSaveToken(token);
      onStepChange(RESET_PASSWORD_STEPS.updatePassword);
    } catch (error) {
      handleErrors(error, formMethods.setError);
      setLoading(false);
    }
  };

  return (
    <Form
      data-testid="forgot-password-token-form"
      onSubmit={onSubmit}
      formMethods={formMethods}
    >
      <Title>
        <FormattedMessage id="common.verificationPassword" />
      </Title>
      <Legend>
        <FormattedMessage id="common.forgotPasswordEmailSent" />
      </Legend>
      <Form.Input
        styles={inputStyles}
        name="token"
        type="number"
        placeholder={intl.messages['common.code']}
      />
      <Form.Button text={intl.messages['common.next']} isLoading={loading} />
    </Form>
  );
};

TokenForm.propTypes = {
  onStepChange: PropTypes.func.isRequired,
  onSaveToken: PropTypes.func.isRequired,
};

export default TokenForm;
