import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const activeStyles = (theme) => css`
  color: ${theme.color.darkGray};
  font-weight: 700;
  background: ${theme.color.gray};
  border-image: ${theme.gradients.primaryToBottom} 1;
  border-right: 0.25rem solid;
  border-top: 0rem;
`;

const menuActiveStyles = (theme) => css`
  background-color: ${theme.colors.secondary};
  color: ${theme.color.white};
  font-weight: 500;
`;

const menuItemStyles = (isActive, theme) => css`
  align-items: center;
  background-color: ${`${theme.colors.secondary}1A`};
  color: ${theme.colors.secondary};
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  padding: 1rem;
  justify-content: center;

  ${isActive && menuActiveStyles(theme)}
`;

export const CircleContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => `${theme.colors.secondary}1A`};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  height: 1.875rem;
  justify-content: center;
  width: 1.875rem;

  img {
    height: auto;
    width: 0.875rem;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 0.06rem solid ${({ theme }) => theme.colors.blueGray};
  bottom: 0rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
  width: ${({ theme }) => theme.width.sidebar};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 80%;
  }

  ${({ isActive, theme }) => isActive && activeStyles(theme)}
`;

export const Avatar = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.full};
  border: solid 0.06rem ${({ theme }) => theme.colors.white};
  min-height: 3rem;
  overflow: hidden;
  min-width: 3rem;

  img {
    height: 3rem;
    max-width: 3rem;
    object-fit: cover;
    width: 3rem;
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.text};
  flex-grow: 1;
  margin-left: 0.2rem;
`;

export const OptionsIcon = styled.span`
  transform: ${({ isMenuOpened }) =>
    isMenuOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const Menu = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 5rem;
  height: fit-content;
  position: fixed;
  width: ${({ theme }) => theme.width.sidebar};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 80%;
  }
`;

export const MenuItem = styled.div`
  ${({ isActive, theme }) => menuItemStyles(isActive, theme)};
`;

export const MenuLink = styled(Link)`
  ${({ isActive, theme }) => menuItemStyles(isActive, theme)};
`;

export const Wrapper = styled.div`
  bottom: 0;
  height: fit-content;
  position: fixed;
  width: ${({ theme }) => theme.width.sidebar};
  z-index: 4;
`;
