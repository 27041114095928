import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Container, Text } from './Footer.styles';

const Footer = ({ noSidebar, styles }) => (
  <Container styles={styles}>
    <Text noSidebar={noSidebar}>
      <FormattedMessage id="common.copyright" />
    </Text>
  </Container>
);

Footer.defaultProps = {
  noSidebar: false,
  styles: () => {},
};

Footer.propTypes = {
  noSidebar: PropTypes.bool,
  styles: PropTypes.func,
};

export default Footer;
