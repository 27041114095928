import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Error, Input, InputContainer, Label } from './Input.styles';

const FormInput = ({
  containerStyles,
  defaultValue,
  id,
  label,
  name,
  onChange,
  placeholder,
  testId,
  ...rest
}) => {
  const { register, errors, watch } = useFormContext();
  const [value, setValue] = useState(defaultValue || '');
  const error = errors[name];
  const formValue = watch(name, '');

  const handleOnChange = ({ target }) => {
    setValue(target.value);
    onChange({ target });
  };

  useEffect(() => {
    setValue(formValue);
  }, [formValue]);

  return (
    <InputContainer
      containerStyles={containerStyles}
      value={defaultValue || value}
      error={error}
    >
      <Input
        {...rest}
        aria-label={id ?? name}
        defaultValue={defaultValue}
        id={id ?? name}
        name={name}
        onChange={handleOnChange}
        ref={register}
        data-testid={testId}
      />
      <Label htmlFor={id ?? name}>
        {placeholder ?? label ?? <FormattedMessage id={`common.${name}`} />}
      </Label>
      {error && <Error>{error.message}</Error>}
    </InputContainer>
  );
};

FormInput.defaultProps = {
  containerStyles: () => {},
  defaultValue: '',
  id: null,
  label: null,
  placeholder: null,
  styles: () => {},
  testId: null,
  onChange: () => {},
};

FormInput.propTypes = {
  containerStyles: PropTypes.func,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  styles: PropTypes.func,
  testId: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormInput;
