import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const inputContainerStyles = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerOption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Option = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 3.125rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin: 0.938rem;
  padding: 0.313rem 0.75rem 0.313rem 2rem;
  position: relative;
  z-index: 1;
`;

export const OptionText = styled.span`
  margin-left: -1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9.375rem;
`;

export const DeleteIcon = styled.span`
  background-color: ${({ theme }) => `${theme.colors.white}4D`};
  border-radius: 50%;
  min-height: 0.938rem;
  margin-left: 0.625rem;
  padding: 0.25rem;
  min-width: 0.938rem;

  img {
    height: 100%;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  margin-bottom: 0.75rem;
  margin-top: 0.8rem;
  position: relative;
  width: 100%;

  &:focus-within {
    label {
      color: ${({ theme }) => theme.colors.secondary};
      top: -0.75rem;
    }
  }

  ${({ containerStyles = () => {}, theme }) => containerStyles(theme)}
`;

export const ContainerInput = styled.div`
  display: flex;
`;

export const ContainerAmount = styled.div`
  display: flex;
  margin: 0 0.625rem;
`;
