import { createContext, useCallback, useContext, useState } from 'react';

import { useAuth } from 'features/auth';
import Loading from '../components/Loading';
import { useCompany } from '../hooks/queries/company';

import { loadingStyles } from '../components/App/App.styles';

const CompanyContext = createContext();

// eslint-disable-next-line react/prop-types
export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState({});

  const {
    user: { companyId },
  } = useAuth();

  const { error, isError, isLoading } = useCompany(companyId, {
    onSuccess: (data) => {
      setCompany(data?.data?.company);
    },
  });

  const updateCurrentCompany = useCallback((values) => {
    setCompany((previousState) => ({ ...previousState, ...values }));
  }, []);

  if (isLoading) {
    return <Loading styles={loadingStyles} />;
  }

  if (isError) {
    return <p>{error.errors[0]}</p>;
  }

  return (
    <CompanyContext.Provider
      value={{
        company,
        updateCurrentCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCurrentCompany = () => useContext(CompanyContext);
