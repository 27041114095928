import PropTypes from 'prop-types';

import Sidebar from 'features/app/components/Sidebar';
import Footer from 'features/common/components/Footer';

import { AuthContainer, footerStyles, Wrapper } from './AuthLayout.styles';

const AuthLayout = ({ children, noSidebar }) => (
  <Wrapper>
    {!noSidebar && <Sidebar />}
    <AuthContainer>{children}</AuthContainer>
    <Footer noSidebar={noSidebar} styles={footerStyles} />
  </Wrapper>
);

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
  noSidebar: PropTypes.bool,
};

AuthLayout.defaultProps = {
  noSidebar: false,
};

export default AuthLayout;
