import styled from '@emotion/styled';

export const BackButtonContainer = styled.button`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.8rem;
  text-align: left;
`;

export const BackButtonText = styled.div`
  flex: 1;
  justify-content: center;
  padding-left: 0.5rem;
`;

export const ArrowBack = styled.img`
  height: 0.75rem;
  width: 0.75rem;
`;
