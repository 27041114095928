import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Loading from '../Loading';

import { Button, loadingStyles, IconRight, ButtonText } from './Form.styles';

const FormButton = forwardRef(
  (
    { isDisabled, isLoading, isSuccess, text, styles, testId, ...rest },
    ref
  ) => (
    <Button
      ref={ref}
      type="submit"
      disabled={isDisabled || isLoading}
      styles={styles}
      data-testid={testId}
      {...rest}
    >
      {!isLoading && !isSuccess && (
        <>
          <ButtonText>{text}</ButtonText>
          <IconRight icon="angle-right" size="lg" />
        </>
      )}
      {isLoading && !isSuccess && <Loading styles={loadingStyles} />}
      {!isLoading && isSuccess && <IconRight icon="check" size="lg" />}
    </Button>
  )
);

FormButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isSuccess: false,
  styles: () => {},
  testId: null,
};

FormButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  text: PropTypes.string.isRequired,
  styles: PropTypes.func,
  testId: PropTypes.string,
};

export default FormButton;
