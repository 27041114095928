import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import close from 'assets/images/close--white.svg';
import { formatOptionsByName } from 'features/settings/utils/options';
import SecondarySelect from './SecondarySelect';

import {
  DeleteIcon,
  Icon,
  IconContainer,
  Option,
  OptionText,
  SelectedOptions,
} from './ResultsInput.styles';

const ResultsInput = ({
  options,
  onOptionDelete,
  onSelect,
  selectedOptions,
  ...leftOverProps
}) => {
  const intl = useIntl();

  const formattedOptions = formatOptionsByName(options);
  const formattedSelection = formatOptionsByName(selectedOptions);
  const getIcon = useCallback(
    (option) => options.find(({ name }) => name === option)?.imageUrl,
    [options]
  );

  return (
    <>
      <SecondarySelect
        {...leftOverProps}
        options={formattedOptions}
        onSelect={onSelect}
      />
      {selectedOptions.length > 0 && (
        <SelectedOptions>
          {formattedSelection.map((option) => {
            const icon = getIcon(option);

            return (
              <Option key={option}>
                {icon && (
                  <IconContainer>
                    <Icon src={icon} alt={intl.messages['common.icon']} />
                  </IconContainer>
                )}
                <OptionText icon={icon}>{option}</OptionText>
                <DeleteIcon onClick={() => onOptionDelete(option)}>
                  <img alt="Delete" src={close} />
                </DeleteIcon>
              </Option>
            );
          })}
        </SelectedOptions>
      )}
    </>
  );
};

ResultsInput.defaultProps = {
  onOptionDelete: () => {},
  selectedOptions: [],
};

ResultsInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  onOptionDelete: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ),
};

export default ResultsInput;
