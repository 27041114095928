import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Input from './Input';

import {
  Container,
  inputContainerStyles,
  Option,
  OptionsContainer,
} from './SecondarySelect.styles';

const SecondarySelect = ({
  defaultValue,
  onSelect,
  options,
  ...leftOverProps
}) => {
  const containerRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [optionsToShow, setOptionToShow] = useState(options);
  const intl = useIntl();

  const handleOnChange = ({ target }) => {
    const value = target.value.trim();
    setOptionToShow(() => {
      if (options.length > 0) {
        const filteredOptions = options.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase())
        );
        return filteredOptions.length > 0
          ? filteredOptions
          : [intl.messages['common.noResults']];
      }

      return [];
    });

    setShowOptions(true);
  };

  const handleOptionClick = (option) => () => {
    setShowOptions(false);
    onSelect(option);
  };

  const handleOnClick = useCallback(
    (event) => {
      setShowOptions(true);
      if (!containerRef.current?.contains(event.target)) setShowOptions(false);
    },
    [setShowOptions]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClick);

    return () => {
      document.removeEventListener('mousedown', handleOnClick);
    };
  }, [handleOnClick]);

  useEffect(() => {
    setOptionToShow(options);
  }, [options]);

  return (
    <Container ref={containerRef} showOptions={showOptions}>
      <Input
        {...leftOverProps}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        containerStyles={inputContainerStyles}
      />
      {showOptions && optionsToShow.length > 0 && (
        <OptionsContainer>
          {optionsToShow.map((option) => (
            <Option
              key={option}
              onClick={handleOptionClick(option)}
              type="button"
            >
              {option}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
};

SecondarySelect.defaultProps = {
  defaultValue: '',
};

SecondarySelect.propTypes = {
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default SecondarySelect;
