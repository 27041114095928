const colors = {
  blueGray: 'rgba(82, 103, 169, 0.1)',
  borderGray: '#c4c4c4',
  borderGrayDark: '#bab8b8',
  contentModals: '#030A20',
  darkBlue: '#030A20',
  disabledBackground: '#E2E2E2',
  disabledText: '#BCBCBC',
  error: '#D95D6A',
  event: '#23A455',
  expired: '#FCCCC8',
  facebookBlue: '#1877F2',
  grayBlue: '#A8B3D4',
  grayBlue01: '#dee2ef',
  green: '#34a853',
  green2: '#23A455',
  highlightBackground: '#B1C0E5',
  lightGray: '#E1E1E1',
  lightGray2: '#F8F8F8',
  lightYellow: '#ffffed',
  linkedinBlue: '#2977C9',
  news: '#67258B',
  pending: ' #FFCA7D',
  pendingBackground: 'rgba(251, 188, 5, 0.2)',
  pendingLabelBackground: '#FEF2CE',
  primary: '#B74994',
  published: '#6CB519',
  publishedBackground: '#E4F9CD',
  rejected: '#EB4335',
  secondary: '#5267A9',
  secondary10: 'rgba(82,103,169,0.1)',
  secondary20: 'rgba(82,103,169,0.2)',
  secondary30: 'rgba(168, 179, 212, 0.2)',
  secondary90: 'rgba(82,103,169,0.9)',
  skyBlue: '#40B8E8',
  skyBlue01: '#0E76A8',
  skyBlueGray: '#F4F6FD',
  text: '#595959',
  twitterBlue: '#00ACEE',
  white: '#FFFFFF',
};

const gradients = {
  primaryToBottom: `linear-gradient(to bottom, ${colors.primary}, ${colors.secondary}, ${colors.skyBlue})`,
  backgroundLogin: `linear-gradient(45deg,  ${colors.skyBlue}, ${colors.secondary}, ${colors.primary}, ${colors.pending})`,
  backgroundCompany: `linear-gradient(250.59deg,#b74994 -5.08%,rgba(160, 66, 162, 0.913158) 15.6%,rgba(131, 58, 180, 0.8) 36.27%,rgba(98, 120, 206, 0.898361) 78.87%,#40b8e8 99.55%)`,
  companyCard: 'linear-gradient(261.57deg, #DECCE0 0%, #CFE6F1 100%)',
  companyCardBorder:
    'linear-gradient(79.93deg, rgba(68, 179, 230, 0.7) 2.67%, rgba(177, 75, 154, 0.7) 97.25%)',
  companyCardHighlights:
    'linear-gradient(79.76deg,rgba(69, 179, 230, 0.3) 0%,rgba(176, 75, 154, 0.3) 99.91%)',
  transparentGreen: '#34a8531a',
  gray: '#ffffff66',
};

const overlay = {
  overlayShadow: 'rgba(0, 0, 0, 0.46)',
};

const filters = {
  white:
    'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(61deg) brightness(104%) contrast(101%)',
  gray: 'grayscale(100%)',
};

const palette = {
  colors,
  filters,
  gradients,
  overlay,
};

export default palette;
