import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { Error, InputContainer, Select, Label } from './Input.styles';

const FormSelect = ({
  defaultValue,
  id,
  label,
  name,
  onChange,
  options,
  placeholder,
  ...rest
}) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const { register, errors, watch } = formContext;
  const [value, setValue] = useState(defaultValue);
  const error = errors[name];
  const formValue = watch(name, '');

  const handleOnChange = (event) => {
    onChange(event);
    setValue(event.target.value);
  };

  useEffect(() => {
    setValue(formValue);
  }, [formValue, name]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <InputContainer value={defaultValue || value} error={error}>
      <Label htmlFor={id ?? name}>
        {placeholder ?? label ?? <FormattedMessage id={`common.${name}`} />}
      </Label>
      <Select
        {...rest}
        aria-label={intl.messages[`common.${name}`]}
        id={id ?? name}
        name={name}
        onChange={handleOnChange}
        ref={register}
      >
        {options.map((option) => (
          <option
            key={option.id}
            value={option.id}
            selected={option.id === value}
          >
            {option.name}
          </option>
        ))}
      </Select>

      {error && <Error>{error?.message}</Error>}
    </InputContainer>
  );
};

FormSelect.defaultProps = {
  defaultValue: '',
  label: null,
  id: null,
  onChange: () => {},
  placeholder: null,
  styles: () => {},
};

FormSelect.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  styles: PropTypes.func,
};

export default FormSelect;
