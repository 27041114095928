import { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowIcon from 'assets/images/arrow-icon.png';
import { useAuth } from 'features/auth';
import { PENDING } from '../../../utils/constants';

import {
  Container,
  Icon,
  Label,
  SubNavIcon,
  SubNavLink,
  Title,
} from './SidebarLink.styles';

const SidebarLink = ({ item }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { isAuthenticated, user } = useAuth();
  const [isSubNavOpened, setIsSubNavOpened] = useState(false);

  const hasSubNav = item.subNav?.length > 0;
  const isUserStatusPending = isAuthenticated && user.status === PENDING;
  const isDisabled = isUserStatusPending && !item.public;

  const isActive = pathname === item.path;
  const activeSubNav = item.subNav?.find(({ path }) => pathname === path);

  const showSubNav = () => {
    setIsSubNavOpened((prevState) => !prevState);
  };

  const handleClick = () => {
    if (isDisabled) return;

    showSubNav();
    history.push(item.path);
  };

  useEffect(() => {
    activeSubNav && setIsSubNavOpened(true);
  }, [activeSubNav]);

  return (
    <>
      <Container
        isActive={isActive || activeSubNav}
        isDisabled={isDisabled}
        onClick={handleClick}
        {...item}
      >
        <Label>
          <Icon>{isActive || activeSubNav ? item.activeIcon : item.icon}</Icon>
          <Title>{item.title}</Title>
        </Label>
        {hasSubNav && (
          <SubNavIcon isOpen={isSubNavOpened}>
            <img src={ArrowIcon} alt="Arrow" />
          </SubNavIcon>
        )}
      </Container>
      {hasSubNav &&
        isSubNavOpened &&
        Children.toArray(
          item.subNav.map(({ path, public: publicPath, title }) => {
            const isPathDisabled = isUserStatusPending && !publicPath;

            return (
              <SubNavLink
                to={isPathDisabled ? '#' : path}
                isActive={activeSubNav?.path === path}
                isDisabled={isPathDisabled}
              >
                {title}
              </SubNavLink>
            );
          })
        )}
    </>
  );
};

SidebarLink.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    activeIcon: PropTypes.node.isRequired,
    subNav: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        public: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
      })
    ),
    public: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SidebarLink;
