import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import close from 'assets/images/close--white.svg';
import Input from './Input';

import {
  Container,
  ContainerAmount,
  ContainerInput,
  ContainerOption,
  DeleteIcon,
  inputContainerStyles,
  Option,
  OptionText,
} from './AssignationInput.styles';

const AssignationInput = ({
  availableAmount,
  totalAmount,
  invitations,
  addInvitation,
  onInvitationDelete,
  ...leftOverProps
}) => {
  const intl = useIntl();
  const containerRef = useRef();

  const handleOnDelete = (option) => () => onInvitationDelete(option);

  return (
    <Container ref={containerRef}>
      <ContainerInput>
        <Input {...leftOverProps} styles={inputContainerStyles} />
        <ContainerAmount>
          {availableAmount} / {totalAmount}{' '}
          <FormattedMessage id="experiences.ticketsAssigned" />
        </ContainerAmount>
      </ContainerInput>
      <ContainerOption>
        {invitations.length > 0 &&
          invitations.map((option) => (
            <Option key={option}>
              <OptionText>{option}</OptionText>
              <DeleteIcon onClick={handleOnDelete(option)}>
                <img alt={intl.messages['common.delete']} src={close} />
              </DeleteIcon>
            </Option>
          ))}
      </ContainerOption>
    </Container>
  );
};

AssignationInput.defaultProps = {
  onInvitationDelete: () => {},
  addInvitation: () => {},
};

AssignationInput.propTypes = {
  addInvitation: PropTypes.func,
  availableAmount: PropTypes.number.isRequired,
  invitations: PropTypes.array.isRequired,
  onInvitationDelete: PropTypes.func,
  totalAmount: PropTypes.number.isRequired,
};

export default AssignationInput;
