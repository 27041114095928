import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Label, LabelContent, InputCheckbox } from './Form.styles';

const Checkbox = ({ helpMessage, id, label, name, ...leftoverProps }) => {
  const { register } = useFormContext();

  return (
    <LabelContent>
      <Label htmlFor={id ?? name}>
        <InputCheckbox
          {...leftoverProps}
          id={id ?? name}
          name={name}
          type="checkbox"
          ref={register}
        />
        {label ?? <FormattedMessage id={`common.${name}`} />}
      </Label>
    </LabelContent>
  );
};

Checkbox.defaultProps = {
  helpMessage: '',
  id: null,
  label: null,
  defaultValue: false,
  styles: () => {},
};

Checkbox.propTypes = {
  helpMessage: PropTypes.string,
  defaultValue: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  styles: PropTypes.func,
};

export default Checkbox;
