import { useQuery } from 'react-query';

import { getCompany } from '../../services';

export const useCompany = (companyId, options) => {
  return useQuery(
    ['/companies', companyId],
    () => getCompany(companyId),
    options
  );
};
