import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Error, InputContainer } from './Form.styles';
import { Label, TextArea } from './TextArea.styles';

const FormTextArea = ({
  id,
  defaultValue,
  name,
  label,
  placeholder,
  lightMode,
  hint,
  hintIcon,
  hintMargin,
  invertHint,
  ...rest
}) => {
  const { register, errors, getValues, watch } = useFormContext();
  const [selected, setSelected] = useState(false);
  const [value, setValue] = useState(defaultValue || '');

  const error = errors[name];
  const formValue = watch(name, '');

  const handleEventSelection = () => {
    const values = getValues();

    setSelected((prevState) => {
      if (!values[name]) return !prevState;

      return prevState;
    });
  };

  useEffect(() => {
    setValue(formValue);
  }, [formValue]);

  return (
    <InputContainer error={error} value={value || defaultValue}>
      <TextArea
        {...rest}
        defaultValue={defaultValue}
        id={id ?? name}
        name={name}
        aria-label={id ?? name}
        selected={selected}
        ref={register}
        onFocus={handleEventSelection}
        onBlur={handleEventSelection}
      />
      <Label htmlFor={id ?? name}>
        {placeholder ?? label ?? <FormattedMessage id={`common.${name}`} />}
      </Label>
      {error && <Error>{error.message}</Error>}
    </InputContainer>
  );
};

FormTextArea.defaultProps = {
  id: null,
  defaultValue: '',
  label: null,
  lightMode: false,
  hint: '',
  hintIcon: '',
  hintMargin: null,
  invertHint: false,
  styles: () => {},
};

FormTextArea.propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  lightMode: PropTypes.bool,
  hint: PropTypes.string,
  hintIcon: PropTypes.string,
  hintMargin: PropTypes.number,
  invertHint: PropTypes.bool,
  styles: PropTypes.func,
};

export default FormTextArea;
