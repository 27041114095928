import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const inputContainerStyles = () => css`
  margin: 0;
  z-index: 1;
`;

export const Container = styled.div`
  max-width: 38.875rem;
  margin-bottom: 1rem;
  position: relative;

  ::after {
    content: '';
    display: ${({ showOptions }) => (showOptions ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => theme.border.thin}
      ${({ theme }) => `${theme.colors.secondary}80`};
    border-bottom: 0;
    height: 1.25rem;
    width: 100%;
    position: absolute;
    top: 1.25rem;
  }
`;

export const OptionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.border.thin}
    ${({ theme }) => `${theme.colors.secondary}80`};
  border-radius: 0rem 0rem 0.6rem 0.6rem;
  box-shadow: 0 0.188rem 0.6rem ${({ theme }) => theme.colors.disabledText};
  border-top: 0;
  display: flex;
  flex-direction: column;
  max-height: 10.875rem;
  min-height: 1.875rem;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    margin: 0 0.6rem 0.6rem 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 0.063rem solid ${({ theme }) => theme.colors.secondary};
    border-radius: 0.625rem;
  }
`;

export const Option = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => theme.border.thin}
    ${({ theme }) => theme.colors.disabledText};
  border-radius: 0.3rem;
  font-weight: bold;
  padding: 1.2rem 0.6rem 1.875rem;
  text-align: left;
  transition: all 0.1s ease-in;
  z-index: 3;

  &:last-of-type {
    border-bottom-width: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;
