import styled from '@emotion/styled';

export const Text = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  margin-left: ${({ noSidebar, theme }) =>
    noSidebar ? 0 : theme.width.sidebar};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;

export const Container = styled.footer`
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.disabledBackground}66`};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  height: ${({ theme }) => theme.height.footer};
  justify-content: center;
  padding: 0.5rem;

  ${({ styles }) => styles}
`;
