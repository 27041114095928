import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.gradients.backgroundLogin};
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 640px) {
    margin-left: 0;
    margin-top: 0;
  }
`;

export const AuthContainer = styled.main`
  background: transparent;
  min-height: ${({ theme }) => `calc(100vh - ${theme.height.footer})`};
`;

export const footerStyles = ({ theme }) => css`
  background: transparent;
  color: ${theme.colors.white};
  width: 100%;
`;
