import { EMPTY, MEDIUM, STRONG, WEAK } from 'utils/constants';

const mediumPassword =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
const strongPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*])(?=.{8,})/;

export const checkPasswordStrength = (password) => {
  if (password === '') return EMPTY;
  if (strongPassword.test(password)) return STRONG;
  if (mediumPassword.test(password)) return MEDIUM;

  return WEAK;
};
