import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: inherit;
  min-width: 100%;
  padding: 0 1.25rem;
`;

export const AuthContainer = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: 30px;
  box-shadow: 1px 1px 20px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 36.125rem;
  padding: 2.5rem 3rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 2rem;
  }
`;

export const ViewContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const AuthLogoContainer = styled.div`
  align-self: center;
  height: 4.977rem;
  margin: 2rem;
  width: 9.375rem;
`;

export const LegendContainer = styled.div`
  flex: 1;
  justify-content: center;
  text-align: center;
`;

export const Legend = styled.span`
  color: ${({ theme }) => theme.color.gray700};
`;

export const LegendText = styled.div`
  align-items: center;
  display: flex;
`;

export const NavigationLink = styled(Link)`
  color: ${({ theme }) => theme.color.indigo700};
  grid-area: link;
  grid-column: 1/3;
  grid-row: 3;
  margin-left: 0.5rem;
  text-align: left;
`;
