import { Children, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Content from 'assets/images/content--sidebar.svg';
import Highlight from 'assets/images/highlight.svg';
import Invite from 'assets/images/invite.svg';
import MemberDirectory from 'assets/images/member-directory.svg';
import ActiveContent from 'assets/images/content--active.svg';
import Networking from 'assets/images/networking.svg';
import ActiveNetworking from 'assets/images/networking--active.svg';
import ActiveExperiences from 'assets/images/star--active.svg';
import ActiveInvite from 'assets/images/invite--active.svg';
import ActiveHighlight from 'assets/images/highlight--active.svg';
import ActiveMemberDirectory from 'assets/images/member-directory--active.svg';
import ActivePromote from 'assets/images/promote--active.svg';
import Experiences from 'assets/images/star.svg';
import Promote from 'assets/images/promote.svg';
import Offers from 'assets/images/offers.svg';
import ActiveOffers from 'assets/images/offers--active.svg';

import { paths as contentPaths } from 'features/content/paths';
import { paths as experiencesPaths } from 'features/experiences/paths';
import { paths as memberPaths } from 'features/memberDirectory/paths';
import { paths as offersPaths } from 'features/offers/paths';
import { paths as promotePaths } from 'features/promote/paths';
import { paths as teamPaths } from 'features/team/paths';
import { paths as networkingPaths } from 'features/networking/paths';

import UserOptions from './UserOptions/UserOptions';
import SidebarLink from './SidebarLink';

import { BodyContainer, LinkMenuIcon } from './Sidebar.styles';

const links = [
  {
    title: <FormattedMessage id="highlights.title" />,
    path: '/highlights',
    icon: <LinkMenuIcon src={Highlight} alt="Highlight" />,
    activeIcon: <LinkMenuIcon src={ActiveHighlight} alt="Highlight" />,
    public: true,
    id: 'highlights',
  },
  {
    title: <FormattedMessage id="content.title" />,
    icon: <LinkMenuIcon src={Content} alt="content" />,
    activeIcon: <LinkMenuIcon src={ActiveContent} alt="ActiveContent" />,
    public: true,
    subNav: [
      {
        title: <FormattedMessage id="content.membersOnly" />,
        path: contentPaths.membersOnly,
        public: false,
      },
      {
        title: <FormattedMessage id="content.allContent" />,
        path: contentPaths.allContent,
        public: true,
      },
    ],
  },
  {
    title: <FormattedMessage id="promote.offers" />,
    path: offersPaths.offers,
    icon: <LinkMenuIcon src={Offers} alt="Offers" />,
    activeIcon: <LinkMenuIcon src={ActiveOffers} alt="Offers" />,
    public: false,
  },
  {
    title: <FormattedMessage id="experiences.title" />,
    icon: <LinkMenuIcon src={Experiences} alt="Experiences" />,
    activeIcon: (
      <LinkMenuIcon src={ActiveExperiences} alt="ActiveExperiences" />
    ),
    public: true,
    subNav: [
      {
        title: <FormattedMessage id="common.techUnited" />,
        path: experiencesPaths.techUnited,
        public: false,
      },
      {
        title: <FormattedMessage id="experiences.community" />,
        path: experiencesPaths.community,
        public: true,
      },
    ],
  },
  {
    title: <FormattedMessage id="memberDirectory.title" />,
    path: memberPaths.memberDirectory,
    icon: <LinkMenuIcon src={MemberDirectory} alt="MemberDirectory" />,
    activeIcon: (
      <LinkMenuIcon src={ActiveMemberDirectory} alt="Member Directory" />
    ),
    public: false,
    id: 'member-directory',
  },
  {
    title: <FormattedMessage id="networking.title" />,
    path: networkingPaths.networking,
    icon: <LinkMenuIcon src={Networking} alt="Networking" />,
    activeIcon: <LinkMenuIcon src={ActiveNetworking} alt="Networking" />,
    public: false,
    id: 'networking',
  },
  {
    title: <FormattedMessage id="promote.title" />,
    icon: <LinkMenuIcon src={Promote} alt="Promote" />,
    activeIcon: <LinkMenuIcon src={ActivePromote} alt="ActivePromote" />,
    public: false,
    id: 'promote',
    subNav: [
      {
        title: <FormattedMessage id="promote.offers" />,
        path: promotePaths.offers,
        public: false,
      },
      {
        title: <FormattedMessage id="promote.events" />,
        path: promotePaths.events,
        public: false,
      },
      {
        title: <FormattedMessage id="promote.news" />,
        path: promotePaths.news,
        public: false,
      },
    ],
  },
  {
    title: <FormattedMessage id="highlights.invite" />,
    path: teamPaths.team,
    icon: <LinkMenuIcon src={Invite} alt="Invite" />,
    activeIcon: <LinkMenuIcon src={ActiveInvite} alt="Invite" />,
    public: true,
    id: 'invite-team',
  },
];

const images = [
  Content,
  Highlight,
  Invite,
  MemberDirectory,
  ActiveContent,
  Networking,
  ActiveNetworking,
  ActiveExperiences,
  ActiveInvite,
  ActiveHighlight,
  ActiveMemberDirectory,
  ActivePromote,
  Experiences,
  Promote,
  Offers,
  ActiveOffers,
];

const Body = forwardRef(({ isOpened }, ref) => {
  useEffect(() => {
    images.forEach((image) => {
      const preloaded = new Image();
      preloaded.src = image;

      window[image] = preloaded;
    });
  }, []);

  return (
    <BodyContainer isOpened={isOpened} ref={ref}>
      {Children.toArray(links.map((link) => <SidebarLink item={link} />))}
      <UserOptions />
    </BodyContainer>
  );
});

Body.propTypes = {
  isOpened: PropTypes.bool.isRequired,
};

export default Body;
