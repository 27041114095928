import ForgotPassword from './pages/ForgotPasswordPage';
import SignIn from './pages/SignInPage';
import { paths } from './paths';

export const routes = [
  {
    children: <ForgotPassword />,
    name: 'Forgot Password',
    path: paths.forgotPassword,
  },
  {
    children: <SignIn />,
    name: 'Sign In',
    path: paths.signIn,
  },
];
