/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';

import { handleErrors } from 'helpers/errors';
import Form from 'features/app/components/Form';
import {
  inputStyles,
  HelpLink,
  LabelContent,
} from 'features/app/components/Form/Form.styles';
import { useAuth } from '../../hooks/auth';
import { paths } from '../../paths';

import { buttonStyles } from './SignIn.styles';

const SignInForm = () => {
  const { signIn } = useAuth();
  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = object().shape({
    email: string()
      .email(intl.messages['common.invalidEmail'])
      .required(intl.messages['common.required']),
    password: string().required(intl.messages['common.required']),
  });

  const formMethods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async ({ email, password }) => {
    setIsLoading(true);
    try {
      await signIn({ email, password });
      history.replace('/');
    } catch (error) {
      setIsLoading(false);
      handleErrors(error, formMethods.setError);
    }
  };

  return (
    <Form
      data-testid="signin-form"
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <Form.Input
        styles={inputStyles}
        name="email"
        type="email"
        data-testid="email"
        tabIndex="1"
      />
      <Form.Input
        styles={inputStyles}
        name="password"
        type="password"
        data-testid="password"
        tabIndex="2"
      />
      <LabelContent>
        <HelpLink tabIndex="4" to={paths.forgotPassword}>
          {intl.messages['common.forgotPassword']}
        </HelpLink>
      </LabelContent>
      <Form.Button
        data-testid="login-button"
        isLoading={isLoading}
        styles={buttonStyles}
        text={intl.messages['common.signIn']}
      />
      <>
        {/* <LegendText>
          <Legend>
            <FormattedMessage id="common.dontHaveAccountQuestion" />
          </Legend>
          <NavigationLink to="/sign-up">
            <FormattedMessage id="common.signUp" />
          </NavigationLink>
        </LegendText> */}
      </>
    </Form>
  );
};

export default SignInForm;
